<template>
    <div class="pos-search-global">
        <div class="searchHelp" id="dropdown" v-if="!isMobile">
            <img src="@/assets/images/search-icon.svg" alt="" class="search-icon" />

            <input
                :class="isOpen ? 'open' : 'close'"
                class="text-autocomplete"
                type="text"
                id="input-search-data"
                v-model.trim="searchData"
                placeholder="Search anything..."
                autocomplete="off"
                @input="handleSearch"
                @click="onClickInput"
                @keyup.stop="detectKeyup" />
            <br />

            <button v-if="searchData !== ''" class="close-btn" @click="clearInput">
                <img src="../assets/images/close.svg" alt="" width="18px">
            </button>

            <div class="search-results" :class="isOpen ? 'open-dropdown' : 'close-dropdown'">
                <ul class="no-match-data" v-if="(searchData === '') || (!masterSearchLoading && searchData !== '')"> 
                    <div class="d-flex justify-center align-center flex-column mt-2">
                        <img src="../assets/icons/search-global-icon.svg" alt="" class="mb-2">
                        <h4>Search Anything</h4>
                        <p class="text-center mb-0">
                            Search for Delivery Orders, Containers, Customers and more. Start typing to find what you're looking for.
                        </p>
                    </div>
                </ul>

                <ul class="loading-search-wrapper" v-if="masterSearchLoading && searchData !== ''"> 
                    <div class="loading-search">
                        <v-progress-circular
                            :size="30"
                            color="#0171a1"
                            indeterminate>
                        </v-progress-circular>
                    </div>
                </ul>

                <ul class="has-data" v-if="searchData !== '' && !masterSearchLoading && (searchResults.customers !== null || 
                    searchResults.shipments !== null || searchResults.drivers !== null || 
                    searchResults.deliveryorders !== null)">

                    <div v-if="searchResults.shipments !== 'undefined' && searchResults.shipments !== null">
                        <!-- <p class="dropdown-title"> Shipments </p> -->
                        <p class="dropdown-title"> Containers </p>

                        <div v-for="(data, index) in searchResults.shipments" :key="index">
                            <li class="dropdown-data">
                                <a type="button" @click="goToPageDetails(data, 'containers')" 
                                    class="d-flex justify-space-between align-center">
                                    <div class="d-flex justify-start align-center">
                                        <span class="img-wrapper">
                                            <img src="../assets/icons/container-blue.svg" alt="" width="18px" height="18px">
                                        </span>

                                        <div>
                                            <p class="data-ref text-uppercase">{{ data.trucker_container }}</p>
                                            <p class="data-location">
                                                <span v-if="data.deliveryorder !== null">
                                                    DO #{{ data.deliveryorder.shifl_ref }}
                                                </span>
                                                <span>REF #{{ data.shifl_ref }}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <span class="status-wrapper text-capitalize">
                                        {{ getContainerStatus(data) }}
                                    </span>
                                </a>
                            </li>
                        </div>
                    </div> 

                    <div v-if="searchResults.deliveryorders !== 'undefined' && searchResults.deliveryorders !== null">
                        <p class="dropdown-title" style="border-top: 1px solid #EBF2F5;"> Delivery Orders </p>

                        <div v-for="(data, index) in searchResults.deliveryorders" :key="index">
                            <li class="dropdown-data">
                                <a type="button" @click="goToPageDetails(data, 'do')"
                                    class="d-flex justify-space-between align-center">
                                    <div class="d-flex justify-start align-center">
                                        <span class="img-wrapper">
                                            <img src="../assets/icons/document-blue.svg" alt="" width="18px" height="18px">
                                        </span>
                                        <!-- should only show if the DO is not an incoming DO -->
                                        <div>
                                            <p class="data-ref text-uppercase">DO #{{ data.shifl_ref }}</p>
                                            <p class="data-location">
                                                {{ getDOContainerStatus(data) }} 
                                            </p>
                                        </div>
                                    </div>

                                    <span class="status-wrapper text-capitalize">
                                        {{ getDOStatus(data) }}
                                    </span>
                                </a>
                            </li>
                        </div>
                    </div>

                    <div v-if="searchResults.customers !== 'undefined' && searchResults.customers !== null">
                        <p class="dropdown-title" style="border-top: 1px solid #EBF2F5;"> Customers </p>

                        <div v-for="(data, index) in searchResults.customers" :key="index">
                            <li class="dropdown-data">
                                <a type="button" @click="goToPageDetails(data, 'customers')">
                                    <span class="img-wrapper">
                                        <img src="../assets/icons/warehouse-icon.svg" alt="" width="18px" height="18px">
                                    </span>
                                    <div>
                                        <p class="data-ref" style="margin-bottom: 2px !important;">{{ data.company_name || data.compnay_name }}</p>
                                        <p class="data-location d-flex align-center">
                                            <span class="d-flex align-center mr-3" v-if="data.phone !== null && data.phone !== ''">
                                                <img src="../assets/icons/phone.svg" class="mr-1" alt="" width="16px" height="16px" 
                                                    style="margin-bottom: 2px;">
                                                <span class="customer-info-search" style="font-size: 12px;">{{ data.phone }}</span>
                                            </span>
                                            <span class="d-flex align-center" v-if="data.emailLists.length > 0">
                                                <img src="../assets/icons/email.svg" class="mr-1" alt="" width="17px" height="17px">
                                                <span class="customer-info-search">
                                                    <span style="font-size: 12px;">{{ data.emailLists[0] }}<span class="email-others" v-if="data.emailLists.length > 1">, +{{ data.emailLists.length - 1 }}</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </a>
                            </li>
                        </div>
                    </div>

                    <div v-if="searchResults.drivers !== 'undefined' && searchResults.drivers !== null">
                        <p class="dropdown-title" style="border-top: 1px solid #EBF2F5;"> Drivers </p>

                        <div v-for="(data, index) in searchResults.drivers" :key="index">
                            <li class="dropdown-data">
                                <a type="button" @click="goToPageDetails(data, 'drivers')">
                                    <span class="img-wrapper">
                                        <img src="../assets/icons/driver-icon.svg" alt="" width="18px" height="18px">
                                    </span>
                                    <div>
                                        <p class="data-ref" style="margin-bottom: 2px !important;">
                                            {{ data.name + ' ' + (data.last_name !== null && data.last_name !== '' ? data.last_name : '') }}
                                        </p>
                                        <p class="data-location d-flex align-center">
                                            <span class="d-flex align-center mr-3" v-if="data.phone_number !== null && data.phone_number !== ''">
                                                <img src="../assets/icons/phone.svg" class="mr-1" alt="" width="16px" height="16px" 
                                                    style="margin-bottom: 2px;">
                                                <span class="driver-info-search" style="font-size: 12px;">{{ data.phone_number }}</span>
                                            </span>
                                            <span class="d-flex align-center" v-if="data.email !== null && data.email !== ''">
                                                <img src="../assets/icons/email.svg" class="mr-1" alt="" width="17px" height="17px">
                                                <span class="driver-info-search" style="font-size: 12px;">{{ data.email }}</span>
                                            </span>
                                        </p>
                                    </div>
                                </a>
                            </li>
                        </div>
                    </div>
                </ul>

                <ul class="no-match-data" 
                    v-if="!masterSearchLoading && searchResults.customers == null && searchResults.shipments == null && 
                    searchResults.drivers == null && searchResults.deliveryorders == null && searchData !== '' && isBeenSearched"> 
                    <div class="d-flex justify-center align-center flex-column mt-2">
                        <img src="../assets/icons/no-result-search.svg" alt="" class="mb-2">
                        <h4>No Result Found</h4>
                        <p class="text-center mb-0">
                            There were no matches found. Try a different keyword
                        </p>
                    </div>
                </ul>
            </div>        
        </div>

        <div class="mobile-search-wrapper" v-if="isMobile">
            <img src="@/assets/images/search-icon.svg" alt="" class="search-icon" />
        </div>

        <AcceptedEditFormDialog
            :openDialog.sync="AcceptedreviewDialog"
            :item="theviewdata"    
            @refresh="refreshAccepted" 
            @closeDialog="closeDialogEditDO('AcceptedreviewDialog')"
            :pageClass="'containers-page-do'"
            :tabsmodel="tabModel"
        />
    </div>
</template>

<script>
import axios from "axios"
import _ from 'lodash'
import moment from 'moment'
import { mapActions, mapGetters } from "vuex";
import AcceptedEditFormDialog from "../components/Tables/DeliveryOrder/dialog/AcceptedEditFormDialog.vue"

var cancel;
var CancelToken = axios.CancelToken;

export default {
    props: ["isMobile"],
    components: {
        AcceptedEditFormDialog
    },
    data() {
        return {
            loading: false,
            items: [],
            search: null,
            select: null,
            searchResults: {
                customers: null,
                shipments: null,
                deliveryorders: null,
                drivers: null
            },
            noResults: false,
            searching: false,
            searchData: '',
            isOpen: false,
            typingTimeout: 0,
            isBeenSearched: false,
            metaobject: [],
            AcceptedreviewDialog: false,
            theviewdata: [],
            tabModel: 0
        };
    },
    watch: {
        // search(val) {
        //     val && val !== this.select && this.querySelections(val);
        // },
        searchData(v) {
            if (v) {
                this.searching = true
                this.isBeenSearched = false
            }
        },
        isOpen(dropOpen) {
            if (dropOpen) {
                document.addEventListener('click', this.closeIfClickedOutside);
            }
        }
    },
    methods: {
        ...mapActions({
			storeEditData: "containers/passData",
            fetchMasterSearch: "fetchMasterSearch",
            fetchShipmentDetails: "fetchShipmentDetails"
		}),
        onClickInput() {
            this.isOpen = true
            this.searching = true
            this.isBeenSearched = false
        },
        cancelRequest() {
            if (cancel !== undefined) {
                cancel()
            }
        },
        clearInput() {
            this.cancelRequest()
            this.searching = false
            this.searchResults = {
                customers: null,
                shipments: null,
                deliveryorders: null,
                drivers: null
            };
            this.searchData = ''
            this.isBeenSearched = false

			setTimeout(() => {
                document.getElementById("input-search-data").focus()
                this.isOpen = true
			}, 1)
		},
        clear() {
            this.cancelRequest()
            this.searching = false
            this.searchResults = {
                customers: null,
                shipments: null,
                deliveryorders: null,
                drivers: null
            };
            this.searchData = ''
            this.isOpen = true
            this.isBeenSearched = false
            // this.isOpen = false
        },
        goToPageDetails(data, isFor) {
            this.isOpen = false
            this.searchData = ''

            if (isFor === "containers") {
                let status = ""
                if (data.tab_status !== "") {
                    status = data.tab_status.replace(/_/g, ' ').split('-')[0];
                    localStorage.setItem("queryData", status);
                }
                this.storeEditData({
                    items: data,
                    objectMeta: this.metaobject,
                    files: this.mgaFiles,
                    drivers: this.magdriver,
                    terminals: this.terminals,
                    customers: this.customers,
                    shipmentsize: this.size,
                    currentState: status
                });
                this.$router.push("/container/edit/" + data.id).catch(()=>{});
                
            } else if (isFor === "do") {
                let passedData = {
                    id: data.id,
                    shifl_ref: data.shifl_ref,
                    otherData: data
                }
                this.AcceptedreviewDialog = true
                this.theviewdata = passedData
                this.getDOTabModel(data)

            } else if (isFor === "customers") {
                // to customers
                this.storeEditData({
                    items: data,
                });
                this.$router.push("/customer-details/" + data.id);
                this.$store.dispatch("page/setPage", "customers");

            } else if (isFor === "drivers") {
                // to drivers
                let count = data.tab_status !== null && data.tab_status === "connected" ? '01' : 
                    (data.tab_status === "not_connected" ? '02' : '03')
                this.$router.push("/drivers-details/" + data.id + `/${count}`).catch(()=>{});
                this.$store.dispatch("page/setPage", "drivers");
            }

            this.searchResults = {
                customers: null,
                shipments: null,
                deliveryorders: null,
                drivers: null
            };
        },
        pgtDebounce(func, delay) {
            let debounceTimer;

            return function() {
                console.log("debouncing call..")
                const context = this
                const args = arguments
                clearTimeout(debounceTimer)
                debounceTimer = setTimeout(() => func.apply(context, args), delay)
                console.log("..done")
            }
        },        
        // handleSearch() {
        //     this.pgtDebounce(this.preApiCall(), 300)
        // },
        // using lodash
        handleSearch: _.debounce(function() {
            this.preApiCall()
        }, 300),
        preApiCall() {
            if (cancel !== undefined) {
                cancel()
                console.log("cancelled");
            } 

            this.apiCall(this.searchData)
        },
        async apiCall(searchData) {
            // this.searching = this.masterSearchLoading
            this.isOpen = true
            this.isBeenSearched = false

            if (searchData !== "undefined" && searchData !== "") {
                let passedData = {
                    method: "post",
                    url: `${process.env.VUE_APP_PO_URL}/search`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c
                    }),
                    params: {
                        search_text: searchData
                    }
                }

                try {
                    await this.fetchMasterSearch(passedData)
                    this.searching = false
                    this.isBeenSearched = true

                    if (this.getAllMasterSearch !== 'undefined' && this.getAllMasterSearch !== null) {
                        let { shipments, customers, deliveryorders, drivers } = this.getAllMasterSearch

                        this.searchResults.shipments = shipments.length > 0 ? shipments : null
                        this.searchResults.deliveryorders = deliveryorders.length > 0 ? deliveryorders : null
                        this.searchResults.drivers = drivers.length > 0 ? drivers : null

                        if (customers.length > 0) {
                            customers.map(v => {
                                if (v.emails !== "")
                                    v.emailLists = v.emails.split(',')
                                else v.emailLists = []
                                
                                return { ...v }
                            })
                        }
                        
                        this.searchResults.customers = customers.length > 0 ? customers : null
                    } else {
                        this.searchResults = {
                            customers: null,
                            shipments: null,
                            deliveryorders: null,
                            drivers: null
                        };
                    }
                } catch (e) {
                    this.searching = false
                    throw Error(e)
                }
            } else {
                this.clear()
            }
        },
        closeIfClickedOutside(event) {
            if (document.getElementById('dropdown').contains(event.target) !== null) {
                if (!document.getElementById('dropdown').contains(event.target)) {
                    // document.getElementById("input-search-data").focus()

                    this.isOpen = false
                    this.searchData = ''
                    this.searching = false
                    this.cancelRequest()
                    this.searchResults = {
                        customers: null,
                        shipments: null,
                        deliveryorders: null,
                        drivers: null
                    };
                    document.removeEventListener('click', this.closeIfClickedOutside)
                }
            }
        },
        async goToShipmentDetails(data) {
            // this.$router.replace(`shipment/${data.id}?status=${data.Status}`)
            // this.$router.push({ path: `shipment/${data.id}`, query: { status: data.Status}  })

            try {
                this.isOpen = false
                this.searchData = ''
                await this.fetchShipmentDetails(data.id)
                this.clear()
            } catch (e) {
                console.log(e);
            }
        },
        date: function (date) {
            return moment(date).format('MM/DD/YYYY');
        },
        detectKeyup() {
            this.isOpen = true
            this.searching = true
        },
        getDOContainerStatus(data) {
            let count = 0
            let finalDisplay = ""
            let dispatch_schedule = null

            if (data.shipments !== null && data.shipments.length > 0) {
                let shipmentsCount = data.shipments.length
                let shipments = data.shipments

                if (shipments.length > 0) {
                    shipments.map(v => {
                        dispatch_schedule = JSON.parse(v.dispatch_schedule);
                        let legs = dispatch_schedule ? dispatch_schedule.legs || [] : []

                        if (legs.length > 0) {
                            let findDeliveredVal = _.find(legs, e => (
                                e.arrival_at_delivery_location_date !== null && 
                                e.arrival_at_delivery_location_date !== "" && 
                                e.type === "Customer")
                            )

                            if (findDeliveredVal !== undefined) {
                                count+=1
                            }

                            // console.log(findDeliveredVal);

                            // let deliveredContCount = _.filter(legs, e => (
                            //     e.arrival_at_delivery_location_date !== null && 
                            //     e.arrival_at_delivery_location_date !== "" && 
                            //     e.type === "Customer")
                            // )

                            // console.log(deliveredContCount);
                            // count = deliveredContCount.length
                        }
                    })

                    finalDisplay = `${count}/${shipmentsCount} Delivered`
                }
            }

            return finalDisplay
        },        
        getContainerStatus(data) {
            let statusDisplay = ""
            if (data !== null && typeof data.tab_status !== "undefined" && data.tab_status !== null) {
                let status = data.tab_status

                if (status !== "") {
                    // remove multiple characters in one line
                    // remove _ and replace with space, remove - and replace with :, remove the for at the customer
                    statusDisplay = status.replace(/[_-]/g, x => ({'_': ' ', '-': ': '})[x]).replace("the", '');
                }
            }
            return statusDisplay
        },
        getDOStatus(data) {
            let statusDisplay = ""
            if (data !== null && data.reviewStatus !== null) {
                let status = data.reviewStatus
                
                if (status === "Pending")
                    statusDisplay = "Incoming"
                else if (status === "Accepted") {
                    statusDisplay = "Opened"

                    if (data.archived === 1) {
                        statusDisplay = "Archived"
                    }
                } else 
                    statusDisplay = "Archived"
            }
            return statusDisplay
        },
        getDOTabModel(data) {
            if (data !== null && data.reviewStatus !== null) {
                let status = data.reviewStatus
                
                if (status === "Pending")
                    this.tabModel = 0
                else if (status === "Accepted") {
                    this.tabModel = 1

                    if (data.archived === 1) {
                        this.tabModel = 2
                    }
                } else 
                    this.tabModel = 0
            }
        },
        refreshAccepted(c) {
            if (c) {
                console.log(c)
            }
        },
        closeDialogEditDO(data) {
            this[data] = false
        },
    },
    computed: {
        ...mapGetters({
            getAllMasterSearch: "getAllMasterSearch",
            masterSearchLoading: "masterSearchLoading",
            getAllcontainerStatus: "containers/getAllcontainersStatus",
        }),
        mgaFiles() {
			var data = [];
			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.components !== "undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined") {
					data = this.getAllcontainerStatus.components.files;
				}
			}
			return data;
		},
		customers() {
			var data = [];
			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.components !== "undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined") {
					data = this.getAllcontainerStatus.components.customers;
				}
			}
			return data;
		},
		terminals() {
			var data = [];
			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.components !== "undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined") {
					data = this.getAllcontainerStatus.components.terminal;
				}
			}
			return data;
		},
		size() {
			var data = [];
			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.components !== "undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined") {
					data = this.getAllcontainerStatus.components.size;
				}
			}
			return data;
		},
		magdriver() {
			var data = [];
			if (typeof this.getAllcontainerStatus !== "undefined" &&
				this.getAllcontainerStatus !== null) {
				if (typeof this.getAllcontainerStatus.components !== "undefined" &&
					this.getAllcontainerStatus.components.length !== "undefined") {
					data = this.getAllcontainerStatus.components.drivers;
				}
			}
			return data;
		},
    },
    mounted() { },
    created() { },
    updated() { }

};
</script>

<style>
@import '../assets/css/shipments_styles/searchGetHelp.css';
</style>
