<template>
  <v-container style="padding-right: 15px">
    <!-- <v-row justify="end align-center"> -->
    <v-row :class="!isMobile ? 'row-desktop' : 'row-mobile'">
      <!-- <v-badge :content="messages" :value="messages" color="green" overlap>
                <img src="@/assets/images/notification-icon.svg" alt="" width="18" height="18" v-if="!isMobile">   
                <img src="@/assets/images/mobile-notif.png" alt="" v-if="isMobile" class="mobile-notif" />
            </v-badge> -->
      <div class="share-feedback-btn mr-5" @click="openDialog()">
        <span class="share-feedback-text"> Share Feedback </span>
      </div>
      <div>
        <button
          @click="openNotifications()"
          style="position: relative; margin-top: 10px"
        >
          <img src="@/assets/icons/bell.svg" />
          <div
            v-if="getUnreadMessages > 0"
            :class="getUnreadMessages > 99 ? 'large-div' : ''"
            class="notifi-count"
          >
            <p
              class="messages-count"
              :class="getUnreadMessages > 99 ? 'mess-count-la' : ''"
            >
              {{ getUnreadMessages }}
            </p>
          </div>
        </button>
      </div>
      <div>
        <div class="text-center">
          <v-menu offset-y nudge-left="159" nudge-top="-4">
            <template v-slot:activator="{ on, attrs }">
              <button class="help-icon" v-bind="attrs" v-on="on">
                <img src="@/assets/icons/help.svg" />
              </button>
            </template>
            <v-list style="width: 200px">
              <a
                href="https://shifl-blog.netlify.app/tutorial"
                target="_blank"
                class="help-link"
              >
                <v-list-item class="help-links">
                  <v-list-item-title>Video Tutorial</v-list-item-title>
                </v-list-item>
              </a>
              <a
                href="https://shifl-blog.netlify.app/glossary"
                class="help-link"
                target="_blank"
              >
                <v-list-item class="help-links">
                  <v-list-item-title>Search Glossary</v-list-item-title>
                </v-list-item>
              </a>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-menu offset-y v-if="!isMobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="
              border: 1px solid #ebf2f5;
              border-radius: 4px !important;
              height: 48px;
              width: 200px !important;
            "
            v-bind="attrs"
            v-on="on"
            elevation="0"
            class="avatar-button"
          >
            <span class="iconHolder">
              <img class="imgHolder" src="@/assets/icons/profile-icon.svg" />
            </span>
            <!-- <v-avatar v-if="!isMobile" size="35">
                            <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>                             
                            <v-icon small class="mr-2"> mdi-account </v-icon> 
                        </v-avatar> -->
            <span class="textCenter" style="">
              {{ dateEmail }}
            </span>
            <span class="arrowDown">
              <img src="@/assets/icons/profile-icon-arrowdown.svg" />
            </span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <!-- <button v-for="(item, index) in items" :key="index">{{ item.title }}</button> -->
            <v-btn @click="userLogout" class="btn-logout">
              <v-icon
                aria-hidden="false"
                style="
                  color: #000 !important;
                  margin-right: 5px !important;
                  font-size: 20px !important;
                "
              >
                mdi-logout
              </v-icon>
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <FeedbackDialog
      :openFeedback="openFeedback"
      @success="successFeedback"
      @closeDialog="closeDialog"
      @closeDialogOnSuccess="closeDialogOnSuccess"
    />
    <FeedbackDialogSuccess
      :openFeedbackSuccess="openFeedbackSuccess"
      @closeDialogSuccess="closeDialogSuccess"
      @closeOnSMF="closeOnSMF"
    />
    <NoificationDialog :getMessages="getMessages" />
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import FeedbackDialog from "../components/Dialog/FeedbackDialog.vue";
import FeedbackDialogSuccess from "../components/Dialog/FeedbackDialogSuccess.vue";
import NoificationDialog from "../components/Dialog/NoificationDialog.vue";
const APIBaseUrl = process.env.VUE_APP_PO_URL;
export default {
  name: "Notification",
  props: ["isMobile"],
  components: {
    FeedbackDialog,
    FeedbackDialogSuccess,
    NoificationDialog,
  },
  data() {
    return {
      messages: 0,
      show: false,
      dateEmail: "",
      items: [{ title: "Logout" }],
      openFeedback: false,
      openFeedbackSuccess: false,
    };
  },
  computed: {
    ...mapGetters("notifications", ["getMessages", "getUnreadMessages"]),
  },
  methods: {
    //
    ...mapActions(["logout"]),
    ...mapActions("notifications", ["establishedConnection"]),
    ...mapMutations("notifications", ["SET_NOTIFICATION_DIALOG"]),

    userLogout() {
      this.$store.dispatch("page/setTab", 1);
      this.logout();
    },
    async getEmail() {
      let parse = JSON.parse(localStorage.getItem("shifl.user_details"));
      let dat = this;
      if (parse.email != "undefined") {
        const url = `${APIBaseUrl}/gettruckersByEmail/` + parse.email;
        await axios
          .get(`${url}`)
          .then(function (response) {
            if (response) {
              let string = response.data.data.name;
              var length = 10;
              dat.dateEmail =
                string.length > 9
                  ? string.substring(0, length) + "..."
                  : string;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    openDialog() {
      this.openFeedback = true;
    },
    closeDialog() {
      this.openFeedback = false;
    },
    closeDialogOnSuccess() {
      this.openFeedback = false;
      this.openFeedbackSuccess = true;
    },
    closeOnSMF() {
      this.openFeedback = true;
      this.openFeedbackSuccess = false;
    },
    closeDialogSuccess() {
      this.openFeedbackSuccess = false;
    },
    successFeedback() {
      this.openFeedback = false;
    },
    openNotifications() {
      this.SET_NOTIFICATION_DIALOG(true);
    },
  },
  mounted() {
    this.getEmail();
    this.establishedConnection();
  },
};
</script>

<style scoped>
.notifi-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #f93131;
  width: 17px;
  height: 17px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.messages-count {
  font-size: 12px;
  color: #fff;
  margin: -2px !important;
  font-family: "Inter-Medium";
}
.mess-count-la {
  font-size: 10px;
}
.large-div {
  height: 20px;
  width: 20px;
  top: -7px;
  right: -7px;
}
.row-desktop {
  justify-content: flex-end;
  align-items: center;
}

.mobile-notif {
  margin-top: 4px;
  width: 18px;
}

.avatar-button {
  padding: 0 !important;
  min-width: 35px !important;
  width: 118px !important;
  border-radius: 0% !important;
  margin: 0 5px 0 25px !important;
  background-color: #fff !important;
}

.btn-logout {
  background-color: transparent !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  letter-spacing: 0;
  font-weight: 600;
}

.avatar-button .v-btn__content .v-avatar i {
  font-size: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
}

.iconHolder {
  border-radius: 999px;
  width: 32px !important;
  display: inline-block !important;
  height: 32px;
  padding-top: 7px;
  position: absolute;
  left: 5px;

  box-sizing: border-box;

  /* Grey BG */
  background: #ebf2f5;
  /* Border Grey */

  border: 1px solid #d8e7f0;
  border-radius: 999px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.arrowDown {
  position: absolute;
  right: 0px;
  padding-right: 9px;
  top: -3px;
}

.imgHolder {
  position: relative;
  top: 0px;
  width: 15.75px !important;
  height: 18px;
}

.textCenter {
  font-size: 14px;
  position: relative;
  width: 135px;
  min-width: 122px;
  overflow: hidden;
  color: rgb(74, 74, 74) !important;
  font-weight: 600;
  left: 7px;
  text-align: left;
}

.textCenter::after {
  content: "";
}

.share-feedback-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  width: 117px;
  height: 30px;
  left: 955px;
  top: 17px;
  background: #f5f9fc;
  border-radius: 4px;
}
.share-feedback-btn:hover {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  width: 117px;
  height: 30px;
  left: 955px;
  top: 17px;
  background: #cbe6fa;
  border-radius: 4px;
}

.share-feedback-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0171a1;
}
.help-icon {
  margin-left: 15px;
  height: 40px;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f0fbff;
}
.help-links:hover {
  background: #f5f9fc;
  cursor: pointer;
}
.help-link {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
}
</style>
