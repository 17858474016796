import axios from "axios";
const APIBaseUrl = process.env.VUE_APP_PO_URL;

const state = {
  messages: [],
  archMessages: [],
  notificationDailog: false,
  currentEditItem: "",
  currentEmailRecpients: "",
  customTimeDailog: false,
  loading: false,
  unreadMessages: 0,
  genralLoader: false,
  emailDailog: false,
  boxOpen: {
    show: null,
    ref: null,
    state: null,
    property: null,
  },
};
const getters = {
  getMessages: (state) => state.messages,
  getArchMessages: (state) => state.archMessages,
  getNotificationDailog: (state) => state.notificationDailog,
  getCurrentEditItem: (state) => state.currentEditItem,
  getCurrentEmailRecpients: (state) => state.currentEmailRecpients,
  getCustomTimeDailog: (state) => state.customTimeDailog,
  getLoading: (state) => state.loading,
  getUnreadMessages: (state) => state.unreadMessages,
  getGenralLoader: (state) => state.genralLoader,
  getEmailDailog: (state) => state.emailDailog,
  getBoxOpen: (state) => state.boxOpen,
};

const actions = {
  getNotificationFromApi({ commit, dispatch }) {
    axios
      .get(`${APIBaseUrl}/get-notification`)
      .then((res) => {
        console.log(res.data);
        commit("SET_MESSAGES", res.data.notification);
        dispatch("calculateUnreadMessages", res.data.notification);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  getArchivedMessages({ commit }) {
    axios
      .get(`${APIBaseUrl}/archive-notification`)
      .then((res) => {
        console.log(res.data);
        commit("SET_ARCH_MESSAGES", res.data.notification);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  establishedConnection({ rootGetters, dispatch }) {
    const truckerId = rootGetters["trucker_Id"];
    if (!truckerId) {
      dispatch("getTruckerIdFromApi").then((res) => {
        dispatch("connectToNotificationChannel", res);
      });
    }
  },
  getTruckerIdFromApi: ({ commit }) => {
    try {
      return new Promise((resolve) => {
        axios.get(`${APIBaseUrl}/get-trucker-info`).then((res) => {
          resolve(res.data.id);
          commit("SET_TRUCKER_ID", res.data.id, { root: true });
          localStorage.setItem("shifl.trucker_Id", res.data.id);
        });
      });
    } catch (e) {
      console.log(e);
    }
  },

  connectToNotificationChannel(context, truckerId) {
    window.Echo.private(`trucker.notification.${truckerId}`).listen(
      "PushNotfication",
      (e) => {
        context.commit("ADD_NEW_MESSAGE", e.message);
        context.commit("ADD_NEW_UNREAD_MESSAGE");
      }
    );
    window.Echo.channel("my").listen("PushNotfication", (e) => {
      console.log(e, "der....");
    });
  },

  // update custom notification
  addRecipients({ commit }, payload) {
    commit("SET_LOADING", true);
    axios
      .post(`${APIBaseUrl}/settings/email-recipients`, payload)
      .then((res) => {
        console.log(res.data);
        commit("profile/SET_COMPANY_PROFILE", res.data.data, { root: true });
        commit("SET_LOADING", false);
        commit("SET_EMAIL_DAILOG", false);
      })
      .catch((e) => {
        commit("SET_LOADING", false);
        console.log(e);
      });
  },
  updateNotificationTime({ commit }, payload) {
    commit("SET_GENRAL_LOADER", true);
    axios
      .post(`${APIBaseUrl}/settings/notification-time`, payload)
      .then((res) => {
        commit("profile/SET_COMPANY_PROFILE", res.data.data, { root: true });
        commit("SET_CUSTOM_TIME_DAILOG", false);
        commit("SET_GENRAL_LOADER", false);

        let payload = {
          show: false,
          ref: null,
          state: null,
          property: null,
        };
        commit("SET_BOX_OPEN", payload);
      })
      .catch((e) => {
        console.log(e);
        commit("SET_CUSTOM_TIME_DAILOG", false);
        commit("SET_GENRAL_LOADER", false);
      });
  },
  updateNotificationStatus({ commit }, payload) {
    axios
      .post(`${APIBaseUrl}/settings/set-notification`, payload)
      .then((res) => {
        commit("profile/SET_COMPANY_PROFILE", res.data.data, { root: true });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  turnOffEmailNotifiApi({ commit }, payload) {
    axios
      .post(`${APIBaseUrl}/settings/turn-off-email`, payload)
      .then((res) => {
        commit("profile/SET_COMPANY_PROFILE", res.data.data, { root: true });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  updateEmailSettings({ commit }, payload) {
    axios
      .post(`${APIBaseUrl}/settings/email-status`, payload)
      .then((res) => {
        commit("profile/SET_COMPANY_PROFILE", res.data.data, { root: true });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  calculateUnreadMessages({ commit }, payload) {
    let count = 0;
    payload.forEach((item) => {
      if (item.read == 0) {
        count++;
      }
    });
    commit("SET_UNREAD_MESSAGES", count);
  },
};
const mutations = {
  SET_MESSAGES: (state, payload) => {
    state.messages = payload;
  },
  SET_ARCH_MESSAGES: (state, payload) => {
    state.archMessages = payload;
  },
  SET_GENRAL_LOADER: (state, payload) => {
    state.genralLoader = payload;
  },
  SET_UNREAD_MESSAGES: (state, payload) => {
    state.unreadMessages = payload;
  },
  ADD_NEW_UNREAD_MESSAGE: (state) => {
    let current = state.unreadMessages;
    state.unreadMessages = current + 1;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  ADD_NEW_MESSAGE: (state, payload) => {
    state.messages.push(payload);
  },
  SORT_MESSAGES: (state, payload) => {
    state.messages = payload;
  },
  SET_NOTIFICATION_DIALOG: (state, payload) => {
    state.notificationDailog = payload;
  },
  SET_CURRENT_EDIT_ITEM: (state, payload) => {
    state.currentEditItem = payload;
  },
  SET_CUSTOM_TIME_DAILOG: (state, payload) => {
    state.customTimeDailog = payload;
  },
  SET_EMAIL_DAILOG: (state, payload) => {
    state.emailDailog = payload;
  },
  CURRENT_EMAIL_RECPIENTS: (state, payload) => {
    state.currentEmailRecpients = payload;
  },
  SET_BOX_OPEN: (state, payload) => {
    state.boxOpen = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
