import axios from "axios"

const state = {
    customers: [],
    customersLoading: false,
	createCustomer: null,
	updateCustomer: null,
	updateCustomerLoading: false,
	createCustomerLoading: false,
	customerSaveAddLoading: false,
	allcustomerDetails: [],
	editViewData: [],
	managereportschedule:[],
	getlastsentreport:null,
	getlastsentreportcount:0,
	CustID:0,
	detailsData:[],
	customersListsLoading: false,
	QBCustomers: [],
	filterQBCustomers: [],
	qbCustomer: "",
	consignees: [],
	isFetching: false,
	isDeleting: false,
	isEditing: false,
	consigneeContacts: "",
	updatingConsigneeContacts: "cancel",
	addingLocation: false,
	locations: []
}

const getters = {
	getcustID: state => state.CustID,
	getlastsentreportcount: state=> state.getlastsentreportcount,
	getLastSentReport: state=> state.getlastsentreport,
	getmanagereportschedule: state =>state.managereportschedule,
	getViewDataCustomers: state => state.editViewData,
    getCustomers: state => state.customers,
    getCustomerLoading: state => state.customersLoading,
	getCreateCustomers: state => state.createCustomer,
    getCreateCustomerLoading: state => state.createCustomerLoading,
	getUpdateCustomers: state => state.updateCustomer,
    getUpdateCustomersLoading: state => state.updateCustomerLoading,
	getCustomersSaveAddLoading: state => state.customerSaveAddLoading,
	getcustomerdetails: state => state.allcustomerDetails,
	getdetailsdata: state => state.detailsData,
	getCustomersListsLoading: state => state.customersListsLoading,
	getQBCustomers: state => state.QBCustomers,
	getFilterQBCustomers: state => state.filterQBCustomers,
	getFetchedQBCustomer: state => state.qbCustomer,
	getConsignees: state => state.consignees,
	getIsFetching: state => state.isFetching,
	getIsEditing: state => state.isEditing,
	getIsDeleting: state => state.isDeleting,
	getConsigneeContacts: state => state.consigneeContacts,
	getIsUpdatingConsigneeContacts: state => state.updatingConsigneeContacts,
	getIsAddingLocation: state => state.addingLocation,
	getLocations: state => state.locations
}

const APIBaseUrl = process.env.VUE_APP_PO_URL
const actions = {
	passData: async ({commit},payload) => {
		commit("EDITVIEWDATA", payload)
	},		
	fetchLastSentReport: async ({commit},payload) => {
		//commit("SET_CUSTOMERS_LOADING", true)
		await axios.get(`${APIBaseUrl}/getlastreport/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {					
					//commit("SET_CUSTOMERS_LOADING", false)
					commit("SET_LAST_SENT_REPORT", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	getManageReportSetting: async ({commit},payload) => {
		//commit("SET_CUSTOMERS_LOADING", true)
		await axios.get(`${APIBaseUrl}/getManageReportSetting/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					//commit("SET_CUSTOMERS_LOADING", false)
					commit("SET_FETCH_MANAGE_REPORT_SCHEDULE", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},

	fetchCustomerDetails: async ({commit},payload) => {
		commit("SET_CUSTOMERS_LOADING", true)
		await axios.get(`${APIBaseUrl}/customer/details/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					
					commit("SET_CUSTOMERS_LOADING", false)
					commit("SET_FETCH_DETAILS_CUSTOMERS", res.data)
					commit("SET_FETCHED_QB_CUSTOMER", res.data.quickbooks_customer)
				}
			}
		})
		.catch(err => {
			commit("SET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
    fetchCustomers: async ({
		commit
	}, ) => {
		commit("SET_GET_CUSTOMERS_LOADING", true)

		await axios.get(`${APIBaseUrl}/customersList`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_GET_CUSTOMERS_LOADING", false)
					commit("SET_CUSTOMERS", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_GET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	fetchCustomersv2: async ({
		commit
	}, page) => {
		commit("SET_GET_CUSTOMERS_LOADING", true)
		commit("SET_CUSTOMERS", [])
		page = page !== undefined ? page : 1
		
		await axios.get(`${APIBaseUrl}/v2/customersList?page=${page}`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_GET_CUSTOMERS_LOADING", false)
					commit("SET_CUSTOMERS", res.data.data)
					commit("SET_GET_QB_CUSTOMERS", res.data.qbCustomers)
					commit("SET_GET_FILTER_QB_CUSTOMERS", res.data.filter)
				}
			}
		})
		.catch(err => {
			commit("SET_GET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	fetchCustomersSearched: async ({
		commit
	}, payload) => {
		commit("SET_GET_CUSTOMERS_LOADING", true)
		commit("SET_CUSTOMERS", [])

		await axios(payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_GET_CUSTOMERS_LOADING", false)
					commit("SET_CUSTOMERS", res.data.data)
				}
			}
		})
		.catch(err => {
			if (err.message !== 'cancel_previous_request') {
				commit("SET_GET_CUSTOMERS_LOADING", false)
			}
			
			return Promise.reject(err)
		})
	},
	setSearchedCustomersLoading({ commit } , payload) {
		commit("SET_GET_CUSTOMERS_LOADING", payload)
	},
    setCustomersSearchedVal({ commit }, payload) {
		commit("SET_CUSTOMERS", payload)
	},
	createCustomers: async ({ commit }, payload ) => {		
        //commit("SET_CREATE_CUSTOMERS_LOADING", true)
		await axios.post(`${APIBaseUrl}/createCustomer`, {
			name: payload.name,
			email: payload.email,
			pnumber: payload.pnumber,		
		})
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					//commit("SET_CREATE_CUSTOMERS_LOADING", false)
					commit("SET_CREATE_CUSTOMERS", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CREATE_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	updateCustomerContacts: async({ commit }, payload) => {
		await axios.post(`${APIBaseUrl}/update-customer-contact/${payload.id}`, payload.form)
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				commit('SET_FETCHED_QB_CUSTOMER', res.data.quickbooks_customer)
			}
		})
		.catch(err => {
			return Promise.reject(err)
		})
	},
	saveConsignee: async({ commit }, payload) => {
		commit("SET_IS_FETCHING", true)
		await axios.post(`${APIBaseUrl}/save-consignee`, payload)
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				commit("SET_CONSIGNEE", res.data.data)
				commit("SET_IS_FETCHING", false)
			}
		})
		.catch(err => {
			return Promise.reject(err)
		})
	},
	updateConsignee: async({ commit }, payload) => {
		commit("SET_IS_EDITING", true)
		await axios.post(`${APIBaseUrl}/update-consignee/${payload.id}`, payload.form)
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				commit("SET_CONSIGNEE", res.data.data)
				commit("SET_IS_EDITING", false)
			}
		})
		.catch(err => {
			return Promise.reject(err)
		})
	},
	deleteConsignee: async ({commit}, payload) => {
		commit("SET_IS_DELETING", true)
		await axios.post(`${APIBaseUrl}/delete-consignee/${payload.customer_id}`, payload.payload)
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				commit("SET_CONSIGNEE", res.data.data)
				commit("SET_IS_DELETING", false)
			}
		})
		.catch(err => {
			return Promise.reject(err)
		})
	},
	updateConsigneeContacts: async({ commit }, payload) => {
		commit('SET_IS_UPDATING_CONSIGNEE_CONTACTS', "")
		await axios.post(`${APIBaseUrl}/update-consignee-contact/${payload.id}`, payload.form)
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				commit('SET_IS_UPDATING_CONSIGNEE_CONTACTS', payload.method)
				commit('SET_FETCHED_CONSIGNEE_CONTACTS', res.data.contacts)
			}
		})
		.catch(err => {
			return Promise.reject(err)
		})
	},
	addDeliveryLocation: async({ commit }, payload) => {
		commit('SET_IS_ADDING_LOCATION', true)
		await axios.post(`${APIBaseUrl}/add-delivery-location`, payload)
		.then(res => {
			if (res.status === 200) {
				console.log(res.data)
				commit('SET_IS_ADDING_LOCATION', false)
				commit('SET_FETCHED_DELIVERY_LOCATIONS', res.data.locations)
			}
		})
		.catch(err => {
			return Promise.reject(err)
		})
	},
}

const mutations = {	
	EDITVIEWDATA: (state,payload) => {
		state.detailsData = payload
	},	
	SET_LAST_SENT_REPORT: (state, payload) => {		
		state.getlastsentreport = payload.data
		state.getlastsentreportcount = payload.count
		state.CustID = payload.customer_id
	},
	SET_FETCH_MANAGE_REPORT_SCHEDULE: (state, payload) => {
		state.managereportschedule = payload
	},
	SET_FETCH_DETAILS_CUSTOMERS: (state, payload) => {
		state.allcustomerDetails = payload
	},
    SET_CUSTOMERS: (state, payload) => {
		state.customers = payload
	},
	SET_CUSTOMERS_LOADING: (state, payload) => {
		state.customersLoading = payload
	},
	SET_GET_CUSTOMERS_LOADING: (state, payload) => {
		state.customersListsLoading = payload
	},
	SET_CREATE_CUSTOMERS: (state, payload) => {
		state.createCustomer = payload
	},
	SET_CREATE_CUSTOMERS_LOADING: (state, payload) => {
		state.createCustomerLoading = payload
	},
	SET_CUSTOMERS_SAVE_ADD_LOADING: (state, payload) => {
		state.customerSaveAddLoading = payload
	},
	SET_UPDATE_CUSTOMERS: (state, payload) => {
		state.updateCustomer = payload
	},
	SET_UPDATE_CUSTOMERS_LOADING: (state, payload) => {
		state.updateSuppliersLoading = payload
	},
	SET_GET_QB_CUSTOMERS: (state, payload) => {
		state.QBCustomers = payload
	},
	SET_GET_FILTER_QB_CUSTOMERS: (state, payload) => {
		state.filterQBCustomers = payload
	},
	SET_FETCHED_QB_CUSTOMER: (state, payload) => {
		state.qbCustomer = payload
	},
	SET_CONSIGNEE: (state, payload) => {
		state.consignees = payload
	},
	SET_IS_FETCHING: (state, payload) => {
		state.isFetching = payload
	},
	SET_IS_EDITING: (state, payload) => {
		state.isEditing = payload
	},
	SET_IS_DELETING: (state, payload) => {
		state.isDeleting = payload
	},
	SET_FETCHED_CONSIGNEE_CONTACTS: (state, payload) => {
		state.consigneeContacts = payload
	},
	SET_IS_UPDATING_CONSIGNEE_CONTACTS: (state, payload) => {
		state.updatingConsigneeContacts = payload
	},
	SET_IS_ADDING_LOCATION: (state, payload) => {
		state.addingLocation = payload
	},
	SET_FETCHED_DELIVERY_LOCATIONS: (state, payload) => { 
		state.locations = payload
	}
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}