import axios from "axios"

const state = {
    deliveryorders: [],  
	deliveryordersLoading: false
}

const getters = {
    getalldeliveryorders: state => state.deliveryorders,   
	getalldeliveryordersLoading: state => state.deliveryordersLoading,   
}

const APIBaseUrl = process.env.VUE_APP_PO_URL
const actions = {
    fetchDeliveryOrders: async ({
		commit
	}, payload ) => {
		commit("SET_DELIVERY_ORDER_LOADING", true)
		await axios.get(`${APIBaseUrl}/getall-delivery-order/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_DELIVERY_ORDER_LOADING", false)
					commit("SET_DELIVERY_ORDER", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_DELIVERY_ORDER_LOADING", false)
			return Promise.reject(err)
		})
	},
	fetchDeliveryOrdersAccepted: async ({
		commit
	}, payload ) => {
		commit("SET_DELIVERY_ORDER_LOADING", true)
		await axios.get(`${APIBaseUrl}/getall-delivery-order-accepted/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_DELIVERY_ORDER_LOADING", false)
					commit("SET_DELIVERY_ORDER", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_DELIVERY_ORDER_LOADING", false)
			return Promise.reject(err)
		})
	},
	fetchDeliveryOrdersPending: async ({
		commit
	}, payload ) => {
		commit("SET_DELIVERY_ORDER_LOADING", true)
		await axios.get(`${APIBaseUrl}/get-pending-delivery-order/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_DELIVERY_ORDER_LOADING", false)
					commit("SET_DELIVERY_ORDER", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_DELIVERY_ORDER_LOADING", false)
			return Promise.reject(err)
		})
	},
}

const mutations = {
    SET_DELIVERY_ORDER: (state, payload) => {
		state.deliveryorders = payload
	},
	SET_DELIVERY_ORDER_LOADING: (state, payload) => {
		state.deliveryordersLoading = payload
	},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}