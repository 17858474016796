<template>
  <div>
    <div class="notifi-modal-body">
      <div
        class="notifi-item"
        v-for="message in getArchMessages"
        :key="message.id"
        @mouseenter="showUnarchiveBtn(message.id, true)"
        @mouseleave="showUnarchiveBtn(message.id, false)"
      >
        <div class="card-right">
          <p class="item-head-row">
            <span class="item-heading">{{ message.name }} </span> has
            {{ message.message }}
            <span class="item-heading id-text">{{
              "#" + message.notification_id
            }}</span
            >{{ message.message == "marked Container" ? " empty" : "" }}
          </p>
          <div class="ref-row">
            <p class="time-text">
              {{ formatTime(message.created_at) }}
            </p>
            <button
              class="archive-section"
              style="margin-right: 7px !important; margin-bottom: 20px"
              v-if="unarchiveBtn.status && unarchiveBtn.id == message.id"
              @click="UnarchiveMessage(message.id)"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @mouseenter="setArchiveTooltip(message.id, true)"
                @mouseleave="setArchiveTooltip(message.id, false)"
              >
                <path
                  d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
                  fill="#0171a1"
                />
              </svg>
              <div v-if="hoverInfo.status && hoverInfo.id == message.id">
                <div class="cone-arch-unarchive" />
                <div class="info-div-arch-unarchive">
                  <p style="margin-top: 15px" class="white--text">UnArchive</p>
                </div>
              </div>

              <!-- <p class="time-text">{{today}}</p> -->
            </button>
            <!-- <p class="cont-ref-text">{{ message.shifl_ref }}</p> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom Floating Div -->
    <div class="btm-div">
      <button @click="unarchiveAllMessages()">
        <p class="btm-btn-text">Unarchive All</p>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const APIBaseUrl = process.env.VUE_APP_PO_URL;
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["getArchMessages"],
  data() {
    return {
      unarchiveBtn: {
        id: null,
        status: false,
      },
      hoverInfo: {
        id: null,
        status: false,
      },
    };
  },

  methods: {
    ...mapMutations("notifications", ["SET_MESSAGES", "SET_ARCH_MESSAGES"]),
    formatTime(time) {
      const targetDate = moment(time); // Replace with your target date

      const currentDate = moment();
      const daysAgo = currentDate.diff(targetDate, "days");

      let formattedDate;

      if (daysAgo === 0) {
        formattedDate = "today";
      } else if (daysAgo === 1) {
        formattedDate = "yesterday";
      } else {
        formattedDate = `${daysAgo} days ago`;
      }
      return formattedDate;
    },
    setArchiveTooltip(id, current) {
      this.hoverInfo.id = id;
      this.hoverInfo.status = current;
    },
    showUnarchiveBtn(id, current) {
      this.unarchiveBtn.id = id;
      this.unarchiveBtn.status = current;
    },
    UnarchiveMessage(id) {
      axios
        .get(`${APIBaseUrl}/unarchive-message/${id}`)
        .then((res) => {
          this.SET_MESSAGES(res.data.messages);
          this.SET_ARCH_MESSAGES(res.data.archived);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unarchiveAllMessages() {
      axios
        .get(`${APIBaseUrl}/unarchive-all`)
        .then((res) => {
          this.SET_MESSAGES(res.data.messages);
          this.SET_ARCH_MESSAGES([]);
          this.$emit("showArchivedNotification", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.cone-arch-unarchive {
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #4a4a4a;
  z-index: 2;
}
.info-div-arch-unarchive {
  position: absolute;
  bottom: -29px;
  z-index: 2;
  right: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background-color: #4a4a4a;
  padding: 0px 10px;
  height: 30px;
  border-radius: 4px;
}
</style>
