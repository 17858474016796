import iziToast from "izitoast"
import moment from "moment"

const globalMethods = {
	notificationMessage: (message) => {
        iziToast.success({
            class: 'categories-toast',
            title: '',
            message: `${message}`,
            displayMode: 1,
            position: 'topRight',
            timeout: 3000,
        });
    },
    emailsendToVendor: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#16B442',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3500,            
            progressBar: false, 
            close: false
        });
    },
    dispatchNotifcationError: (error) => {
        iziToast.error({
            class: 'categories-toast',         
            message: error,           
            position: 'bottomCenter',
            timeout: 3000,
            progressBar: false, 
            close: false
        });
    },
    dispatchNotifcation: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#16B442',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3000,            
            progressBar: false, 
            close: false
        });
    },
    dispatchNotifcationReport: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box classNewPosition',           
            message: `${message}`,           
            backgroundColor: '#16B442',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3000,            
            progressBar: false, 
            close: false
        });
    },
    addContainerNotification: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#EBFAEF',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 5000,            
            progressBar: false, 
            close: false
        });
    },
    deliveryNotification: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 10000,            
            progressBar: false, 
            close: false
        });
    },
    showDeleteNotificationBox: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 5000,            
            progressBar: false, 
            close: false
        });
    },
    showPreparingdownload: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3500,            
            progressBar: false, 
            close: false
        });
    },
    notificationError: (error) => {
        iziToast.error({
            class: 'categories-toast',
            title: 'Error',
            message: error,
            displayMode: 1,
            position: 'topRight',
            timeout: 3000,
        });
    },
    notificationErrorCustom: (error) => {
        iziToast.info({
            class: 'product-toast',
            // title: 'Error',
            message: error,
            displayMode: 1,
            position: 'bottomCenter',
            timeout: 3000,
        });
    },
    notificationCustom: (message) => {
        iziToast.info({
            class: "product-toast",
            // title: 'Error',
            message: message,
            displayMode: 1,
            position: "bottomCenter",
            timeout: 3000,
        });
    },
    notificationMessageCustomSuccess(message) {
        iziToast.success({
            class: "izitoast-message-custom",
            theme: "dark",
            message: message,
            backgroundColor: "#16B442",
            messageColor: "#ffffff",
            iconColor: "#ffffff",
            progressBarColor: "#ADEEBD",
            displayMode: 1,
            position: "bottomCenter",
            timeout: 3000,
        });
    },
    notificationCustomAlert: (data) => {
        iziToast.info({
            class: `product-toast ${data.class}`,
            // title: 'Error',
            message: data.message,
            displayMode: 1,
            position: "bottomCenter",
            timeout: data.timeout,
        });
    },
    notificationRedError(message) {
        iziToast.error({
            class: "izitoast-message-custom",
            theme: "dark",
            message: message,
            backgroundColor: "#d91b1b",
            messageColor: "#ffffff",
            iconColor: "#ffffff",
            displayMode: 1,
            position: "bottomCenter",
            timeout: 3000,
        });
    },
}

export function convertDateTimeToUTC(date, time) {
    let dateTogether = "";
    let convertDateTimeToUTC = "";
    let convertedDateAndTime = { newDate: "", newTime: "" }

    if (date !== null && date !== "") {
        let convertDate = moment(date).format("YYYY-MM-DD")

        if (time !== null && time !== "") {
            dateTogether = convertDate + " " + time; // combine date and time
            convertDateTimeToUTC = moment(dateTogether).utc().format("YYYY-MM-DD HH:mm");

            let splitDateAndTime = convertDateTimeToUTC.split(" ");
            if (splitDateAndTime.length === 2) {
                convertedDateAndTime.newDate = splitDateAndTime[0];
                convertedDateAndTime.newTime = splitDateAndTime[1];
            }
        } else {
            convertedDateAndTime.newDate = convertDate;
            convertedDateAndTime.newTime = "";
        }
    }
    return convertedDateAndTime
}

export default globalMethods