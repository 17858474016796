import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import shipment from "./modules/shipment";
import page from "./modules/page";
import shipmentDetails from "./modules/shipmentDetails";
import masterSearch from "./modules/masterSearch";
import scheduleOptions from "./modules/scheduleOptions";
import milestones from "./modules/milestones";
import po from "./modules/po";
import products from "./modules/products";
import category from "./modules/category";
import warehouse from "./modules/warehouse";
import inventory from "./modules/inventory";
import suppliers from "./modules/suppliers";
import containers from "./modules/containers";
import drivers from "./modules/drivers";
import yards from "./modules/yards";
import chassis from "./modules/chassis";
import customers from "./modules/customers";
import common from "./modules/common";
import map from "./modules/Map/map";
import deliveryorder from "./modules/deliveryorder";
import profile from "./modules/CompanyProfile/profile";
import reports from "./modules/reports";
import notifications from "./modules/notifications";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    shipment,
    page,
    shipmentDetails,
    masterSearch,
    scheduleOptions,
    milestones,
    po,
    products,
    category,
    warehouse,
    inventory,
    suppliers,
    containers,
    drivers,
    yards,
    chassis,
    customers,
    common,
    map,
    deliveryorder,
    profile,
    reports,
    notifications,
  },
});
