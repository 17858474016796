<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="620px"
    content-class="add-container-dialog"
    v-resize="onResize"
    :retain-focus="false"
  >
    <v-card class="add-supplier-card">
      <v-card-title>
        <span class="headline"> Move to existing Delivery Order </span>
        <button icon dark class="btn-close" @click="close">
          <v-icon>mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text class="py-6">
        <v-form
          ref="form"
          id="createContainer"
          v-model="valid"
          action="#"
          @submit.prevent=""
          class="pt-2 pb-4"
        >
        <!-- <v-radio-group v-model="modelcheckbox" mandatory hide-details="auto" class="mt-0"> -->
          <v-row>
            <v-col
              v-if="choosenmovetoexistingdo"
              cols="12"
              sm="12"
              md="12"
              class="pb-0 pt-0"
            >
              <label class="label_delivery_order" for=""
                >DELIVERY ORDER</label
              >
              <v-autocomplete
                class="containersizemodal deliveryorderselect move-to-existing"
                v-model="getselectedeliveryDO"
                :items="getalldeliveryoder"
                item-text="shifl_ref"
                item-value="id"
                :placeholder="getalldeliveryordersLoading ? 'Fetching Delivery Orders...' : 'Select Delivery Order'"
                solo
                append-icon="ic-chevron-down"
                :disabled="getalldeliveryordersLoading"
                hide-details="auto"
                :menu-props="{ contentClass: 'move-do-lists-menu' }">

                <template slot="item" slot-scope="{ item }">
                  <div class="listDropdown move-to-existing-dropdown" style="width: 100%">
                    <div style="font-size: 14px; width: 90px;"> DO #{{ checkitem(item) }} </div>
                    <span class="status-label-do" :class="getDOLabel(item)">{{ getDOLabel(item) }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        <!-- </v-radio-group> -->
        </v-form>
      </v-card-text>
      <v-card-actions style="border-top: 2px solid #ebf2f5 !important">
        <div class="text-left buttonActions d-flex align-center justify-start" style="padding-right: 0px; padding-left: 0px">
          <v-btn
            style="caret-color: transparent; font-size: 14px !important;"
            class="btn-blue"
            text
            @click="save"
            :disabled="disabled"
          >
            <span>
              <span style="caret-color: transparent">
                <v-progress-circular
                  :size="15"
                  color="#fff"
                  :width="2"
                  indeterminate
                  v-if="isFetching"
                  style="margin-right: 3px"
                >
                </v-progress-circular>
                Save
              </span>
            </span>
          </v-btn>
          <v-btn class="btn-white" style="font-size: 14px !important;" text @click="close" v-if="!isMobile">
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../../utils/globalMethods";
import _ from "lodash";
export default {
  name: "Movedialog",
  props: ["dialogData", "ContainerSize","locType", "tabsmodel"],
  components: {},
  data() {
    return {
      disabled: false,
      radiochoosen: "",
      getselectedeliveryDO: "",
      movedo: "createdo",
      choosenmovetoexistingdo: 1,
      modelcheckbox: "",
      searchPlace: "",
      loadingLocationPlaces: false,
      places: [],
      containersize: "",
      container_number: "",
      container_size: "",
      container_weight: "",
      container_volume: "",
      container_qty: "",
      DeliveryTo: "",
      typeError: "", // type of error return from api
      isFetching: 0,
      valid: true,
      value: [],
      isMobile: false,
      rules: [(v) => !!v || "Input is required."],
      numberRules: [
        (v) => {
          var reg = new RegExp("^[0-9]+$");
          if (!reg.test(v)) {
            return "Input should be a number";
          }
          return true;
        },
      ],
      container_number_rules: [
        (text) => {
          if (text == "") return "Container # is required.";
          let msg =
            "The first 4 characters are alpha and followed by 7 digits.";
          if (text.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
              /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
              return true;
            }
            return msg;
          }
          return "First 4 characters are alpha and followed by 7 digits.";
        },
      ],
      selectRules: [(v) => !!v || "This field is required"],
      deliveryto: [(v) => !!v || "Delivery To is required"],
    };
  },
  watch: {
    async dialogData(c){
      if(c){
         if(typeof this.locType !='undefined' && this.locType){
            if (this.tabsmodel === 1) {
              await this.fetchDeliveryOrders(this.locType);
            } else {
              await this.fetchDeliveryOrdersPending(this.locType);
            }
         }else{
            if (this.tabsmodel === 1) {
              await this.fetchDeliveryOrders(this.$route.params.id);
            } else {
              await this.fetchDeliveryOrdersPending(this.$route.params.id);
            }
         }
      }
    },
    getselectedeliveryDO(c) {
      if (c) {
        this.disabled = false;
      }
    },
    searchPlace(val) {
      if (val) {
        this.getDeliveryLocation(val);
      }
    },
  },
  computed: {
    ...mapGetters({
      getCustomers: "customers/getCustomers",
      getCustomersLoading: "customers/getCustomerLoading",
      getalldeliveryorders: "deliveryorder/getalldeliveryorders",
      getalldeliveryordersLoading: "deliveryorder/getalldeliveryordersLoading"
    }),
    getalldeliveryoder: {
      get() {
        return this.getalldeliveryorders.data;
      },
    },
    itemsDropdownSize: {
      get() {
        return this.ContainerSize;
      },
    },
    dialog: {
      get() {
        return this.dialogData;
      },
      set(value) {
        if (!value) {
          this.$emit("update:dialogData", false);
        } else {
          this.$emit("update:dialogData", true);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchCustomers: "customers/fetchCustomers",
      createCustomers: "customers/createCustomers",
      fetchDeliveryOrders: "deliveryorder/fetchDeliveryOrders",
      fetchDeliveryOrdersPending: "deliveryorder/fetchDeliveryOrdersPending"
    }),
    ...globalMethods,
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    checkitem(t) {
      return t.shifl_ref;
    },
    getDOLabel(item) {
      let doStatus = ""

      if (item !== null) {
        let status = item.reviewStatus

        if (status !== null && status !== '') {
          if (status === "Pending") {
            doStatus = "Incoming"
          } else if (status === "Accepted") {
            doStatus = "Opened"

            if (item.archived === 1) {
              doStatus = "Archived"
            }
          } else {
            doStatus = "Archived"
          }
        }
      }

      return doStatus
    },
    getradio(c) {
      if (c == 2) {
        this.getselectedeliveryDO = "";
        this.disabled = true;
        this.choosenmovetoexistingdo = 1;
      } else {
        this.choosenmovetoexistingdo = 0;
        this.disabled = false;
      }
    },
    close() {
      this.$refs.form.resetValidation();
      this.$emit("update:dialogData", false);
    },
    async save() {
      var statevalue;
      var dis = this;
      var getobject = _.filter(dis.getalldeliveryorders.data, function (o) {
        return o.id == dis.getselectedeliveryDO;
      });
      this.choosenmovetoexistingdo = 1;
      statevalue = {
        state: "moveDO",
        shifl_ref: getobject[0].shifl_ref,
        selected: this.getselectedeliveryDO,
      };

      this.$emit("save", statevalue);
    },
    setToDefault() {
      this.$emit("setToDefault");
    },
    resetform() {
      this.container_number = "";
      this.container_size = "";
      this.container_weight = "";
      this.container_volume = "";
      this.container_qty = "";
      this.DeliveryTo = "";
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;

            this.places = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    clearSearchPlaces() {
      this.places = [];
    },
  },
  async mounted() {
    //getall-delivery-order
    // await this.fetchDeliveryOrders(this.$route.params.id);
  },
};
</script>
<style lang="scss">
@import "../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../../../assets/scss/pages_scss/deliveryorder/deliveryAddDialog.scss";

.v-autocomplete__content.v-menu__content {
  border-radius: 4px !important;
}
</style>

<style scoped>
.text-field {
  color: red !important;
  /* add whatever properties you want */
}

.v-dialog__content .v-textarea .v-input__slot {
  border: 1px solid #b4cfe0 !important;
}

.containersizemodal .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #b4cfe0 !important;
}
.containersizemodal .v-icon {
  font-size: 13px;
}
.textClass {
  color: #6d858f;
  font-size: 12px;
  padding-left: 34px;
}

.radioChoices {
  margin-bottom: 0px !important;
}
.v-label {
  font-size: 14px !important;
}

.label_delivery_order {
  color: #819fb2;
  font-weight: 600;
  font-size: 10px;
}

/* .deliveryorderselect {
  height: 53px;
} */
</style>

<style lang="scss">
.v-input.deliveryorderselect.move-to-existing {
  &.v-input--is-disabled {
    .v-input__control {
      .v-input__slot {
        background-color: #EBF2F5 !important;
        border: 1px solid #EBF2F5 !important;

        fieldset {
          border: 1px solid #EBF2F5 !important;
          background-color: #EBF2F5 !important;
        }

        input {
          color: #B4CFE0 !important;
        }
      }
    }
  }

  .v-input__control {
    min-height: 40px !important;
  }
}

.v-menu__content.move-do-lists-menu {
  .v-list {
    .v-list-item {
      border-bottom: none !important;
      min-height: 34px !important;

      .listDropdown.move-to-existing-dropdown {
        display: flex;
        justify-content: flex-start;
        align-items: center;

      .status-label-do {
        padding: 4px 8px;
        border-radius: 99px;
        font-size: 11px !important;
        text-align: center;
        font-family: "Inter-Medium", sans-serif;
        height: 23px !important;

        &.Incoming {
          color: #D68A1A !important;
          background-color: #FFF9F0 !important;
        }

        &.Opened {
          color: #16B442 !important;
          background-color: #EBFAEF !important;
        }

        &.Archived {
          color: #f93131 !important;
          background-color: #fff2f2 !important;
        }
      }
    }
    }
  }
}
</style>
