<template>
  <div>
    <v-dialog
      v-model="getNotificationDailog"
      scrollable
      max-width="560px"
      content-class="message-dialog"
      persistent
    >
      <v-card>
        <div class="head-section">
          <span v-if="!showingArchived" class="heading" @click="test"
            >Notifications</span
          >
          <div v-else class="d-flex">
            <button @click="showArchivedNotification(false)">
              <v-icon class="mr-1" style="color: #0171a1"
                >mdi-chevron-left</v-icon
              >
            </button>

            <span class="heading">Archived Notifications</span>
          </div>

          <button
            icon
            dark
            class="btn-close"
            @click="close"
            style="margin-top: -12px"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </div>

        <v-card-text>
          <div
            v-if="getMessages.length && !showingArchived"
            style="padding-bottom: 40px !important"
          >
            <div class="notifi-modal-body">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  padding: 10px 16px;
                "
              >
                <div class="d-flex custom-message-dia" style="margin-top: 8px">
                  <span
                    style="color: #b4cfe0; font-size: 14px; font-weight: 400"
                    >Show</span
                  >
                  <!-- :class="
                      sortBy == 'All' 
                      (sortBy == 'Load Status' && 'small med-small')
                    " -->

                  <v-select
                    style="width: 70%; margin-top: -1px"
                    class="sortItems change-color"
                    v-model="sortBy"
                    dense
                    :class="
                      sortBy == 'All'
                        ? 'small'
                        : sortBy == 'Load Status'
                        ? 'med-small'
                        : ''
                    "
                    :items="items"
                    append-icon="ic-chevron-down"
                  >
                  </v-select>
                </div>
                <button
                  style="text-decoration: none; position: relative"
                  @click="navigateToNotificationSettings"
                >
                  <i
                    class="ic-settings"
                    style="font-size: 24px; color: #0084bd"
                    @mouseenter="showHoverSettingsState(true)"
                    @mouseleave="showHoverSettingsState(false)"
                  ></i>
                  <div v-if="showSettingInfo">
                    <div class="cone-setting-message" />
                    <div class="info-settings">
                      <p style="margin-top: 15px" class="white--text">
                        Notification Settings
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <!-- messsage start -->

              <!-- message end -->

              <!-- item -->
              <div
                class="notifi-item"
                v-for="message in getMessages"
                :key="message.id"
                @mouseenter="showArchiveBtn(message.id, true)"
                @mouseleave="showArchiveBtn(message.id, false)"
              >
                <div class="card-left">
                  <button
                    @click="readMessage(message.id)"
                    v-if="!message.read"
                    class="dot"
                    @mouseenter="showHoverReadState(message.id, true)"
                    @mouseleave="showHoverReadState(message.id, false)"
                  />
                  <div v-if="hoverRead.status && hoverRead.id == message.id">
                    <div class="cone-div-message" />
                    <div class="info-message">
                      <p style="margin-top: 15px" class="white--text">Read</p>
                    </div>
                  </div>
                </div>
                <div class="card-right">
                  <p class="item-head-row">
                    <span class="item-heading">{{ message.name }} </span> has
                    {{ message.message }}
                    <span class="item-heading id-text">{{
                      "#" + message.notification_id
                    }}</span
                    >{{ message.message == "marked Container" ? " empty" : "" }}
                  </p>
                  <div class="ref-row">
                    <p class="time-text">
                      {{ formatTime(message.created_at) }}
                    </p>
                    <!-- <p class="cont-ref-text">{{ message.shifl_ref }}</p> -->
                    <button
                      v-if="hoverBtn.status && hoverBtn.id == message.id"
                      class="archive-section"
                      @click="archiveMessage(message.id)"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @mouseenter="setArchiveTooltip(message.id, true)"
                        @mouseleave="setArchiveTooltip(message.id, false)"
                      >
                        <path
                          d="M13.8498 0.800781C14.5954 0.800781 15.1998 1.4052 15.1998 2.15078V3.95078C15.1998 4.53834 14.8244 5.03824 14.3004 5.22374L14.2998 12.0508C14.2998 13.7905 12.8895 15.2008 11.1498 15.2008H4.84981C3.11011 15.2008 1.69981 13.7905 1.69981 12.0508L1.70007 5.22406C1.17559 5.03881 0.799805 4.53868 0.799805 3.95078V2.15078C0.799805 1.4052 1.40422 0.800781 2.14981 0.800781H13.8498ZM13.3998 5.30078H2.59981V12.0508C2.59981 13.2934 3.60717 14.3008 4.84981 14.3008H11.1498C12.3924 14.3008 13.3998 13.2934 13.3998 12.0508V5.30078ZM6.64981 7.10078H9.34981C9.59834 7.10078 9.79981 7.30225 9.79981 7.55078C9.79981 7.7717 9.64062 7.95543 9.4307 7.99353L9.34981 8.00078H6.64981C6.40128 8.00078 6.19981 7.79931 6.19981 7.55078C6.19981 7.32987 6.35899 7.14613 6.56892 7.10803L6.64981 7.10078H9.34981H6.64981ZM13.8498 1.70078H2.14981C1.90128 1.70078 1.69981 1.90225 1.69981 2.15078V3.95078C1.69981 4.19931 1.90128 4.40078 2.14981 4.40078H13.8498C14.0983 4.40078 14.2998 4.19931 14.2998 3.95078V2.15078C14.2998 1.90225 14.0983 1.70078 13.8498 1.70078Z"
                          fill="#0171a1"
                        />
                      </svg>
                      <div
                        v-if="hoverInfo.status && hoverInfo.id == message.id"
                      >
                        <div class="cone-arch" />
                        <div class="info-div-arch">
                          <p style="margin-top: 15px" class="white--text">
                            Archive
                          </p>
                        </div>
                      </div>

                      <!-- <p class="time-text">{{today}}</p> -->
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bottom Floating Div -->
            <div class="btm-div">
              <button @click="showArchivedNotification(true)">
                <p class="btm-btn-text">View All Archived</p>
              </button>

              <button @click="readAllMessages()">
                <p class="btm-btn-text read-text">Mark All as Read</p>
              </button>
            </div>
          </div>

          <div
            v-else-if="showingArchived"
            style="padding-bottom: 40px !important"
          >
            <ArchivedMessag
              :getArchMessages="getArchMessages"
              @showArchivedNotification="showArchivedNotification"
            />
          </div>
          <!-- No Data -->
          <div v-else class="no-notification">
            <i
              class="ic-notification"
              style="font-size: 60px; color: #d8e7f0"
            ></i>
            <p class="heading-empty">You have no notifications</p>
            <p class="para">
              It looks like you don't have any new notifications at the moment,
              but don't worry - we'll keep you informed as soon as there's
              something new to share
            </p>
            <div class="btm-div">
              <button @click="showArchivedNotification(true)">
                <p class="btm-btn-text">Show Archived</p>
              </button>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";
import ArchivedMessag from "./ArchivedMessage.vue";
const APIBaseUrl = process.env.VUE_APP_PO_URL;

export default {
  name: "NotificationMessage",
  props: ["dialog", "getMessages"],
  components: {
    ArchivedMessag,
  },

  data() {
    return {
      items: ["All", "Schedule Status", "Load Status"],
      changeInput: false,
      sortBy: "All",
      isActive: false,
      hoverInfo: {
        id: null,
        status: false,
      },
      hoverBtn: {
        id: null,
        status: false,
      },
      hoverRead: {
        id: null,
        status: false,
      },
      data: true,
      showSettingInfo: false,
      showingArchived: false,
      smallSelectInput: false,
    };
  },
  watch: {
    sortBy(val) {
      if (!this.getMessages) return;
      console.log(val);

      switch (val) {
        case "Load Status":
          this.sortByLoadStatus();
          break;
        case "All":
          this.sortAll();
          break;
        default:
          this.sortByScheduleStatus();
          break;
      }
    },
  },
  computed: {
    ...mapGetters("notifications", [
      "getNotificationDailog",
      "getArchMessages",
    ]),
  },
  methods: {
    ...mapActions("notifications", [
      "getNotificationFromApi",
      "getArchivedMessages",
      "calculateUnreadMessages",
    ]),
    ...mapMutations("notifications", [
      "SORT_MESSAGES",
      "SET_NOTIFICATION_DIALOG",
      "SET_MESSAGES",
      "SET_ARCH_MESSAGES",
    ]),

    close() {
      this.SET_NOTIFICATION_DIALOG(false);
    },

    changeColor() {
      this.changeInput = true;
      this.isActive = true;
    },
    showHoverReadState(id, current) {
      this.hoverRead.id = id;
      this.hoverRead.status = current;
    },
    showHoverSettingsState(hover) {
      if (hover) {
        this.showSettingInfo = true;
        return;
      }
      this.showSettingInfo = false;
    },
    navigateToNotificationSettings() {
      this.SET_NOTIFICATION_DIALOG(false);
      this.$router.push("settings?tab=notifications").catch(() => {});
    },
    showArchivedNotification(showArchive) {
      if (showArchive) {
        this.showingArchived = true;
        return;
      }
      this.showingArchived = false;
    },
    setArchiveTooltip(id, current) {
      this.hoverInfo.id = id;
      this.hoverInfo.status = current;
    },
    showArchiveBtn(id, current) {
      this.hoverBtn.id = id;
      this.hoverBtn.status = current;
    },
    formatTime(time) {
      const targetDate = moment(time); // Replace with your target date

      const currentDate = moment();
      const daysAgo = currentDate.diff(targetDate, "days");

      let formattedDate;

      if (daysAgo === 0) {
        formattedDate = "today";
      } else if (daysAgo === 1) {
        formattedDate = "yesterday";
      } else {
        formattedDate = `${daysAgo} days ago`;
      }
      return formattedDate;
    },
    sortByLoadStatus() {
      console.log("load");
      const updatedItem = this.getMessages.sort((a, b) => {
        return b.message_type == "tracking_notification" ? 1 : -1;
      });
      this.SORT_MESSAGES(updatedItem);
    },
    sortAll() {
      const updatedItem = this.getMessages.sort((a, b) => {
        const date1 = moment(a.created_at);
        const date2 = moment(b.created_at);
        return date1.isAfter(date2) ? -1 : 1;
      });
      this.SORT_MESSAGES(updatedItem);
    },
    sortByScheduleStatus() {
      console.log("sched");
      const updatedItem = this.getMessages.sort((a, b) => {
        return b.message_type == "driver_notification" ? 1 : -1;
      });
      this.SORT_MESSAGES(updatedItem);
    },
    archiveMessage(id) {
      axios
        .get(`${APIBaseUrl}/archive-message/${id}`)
        .then((res) => {
          this.SET_MESSAGES(res.data.messages);
          this.SET_ARCH_MESSAGES(res.data.archived);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    readMessage(id) {
      axios
        .get(`${APIBaseUrl}/read-message/${id}`)
        .then((res) => {
          this.hoverRead.id = null;
          this.hoverRead.status = false;
          this.calculateUnreadMessages(res.data.messages);
          this.SET_MESSAGES(res.data.messages);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    readAllMessages() {
      axios
        .get(`${APIBaseUrl}/read-all-messages`)
        .then((res) => {
          this.SET_MESSAGES(res.data.messages);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    test() {
      console.log(window.Echo);
    },
  },
  mounted() {},
  updated() {},
  created() {
    this.getNotificationFromApi();
    this.getArchivedMessages();
  },
};
</script>
<style>
.v-dialog__content.v-dialog__content--active
  + .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  margin-top: 30px !important;
  width: 160px !important;
}
</style>
<style lang="scss">
.message-dialog {
  // head css
  .head-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 1px solid #d8e7f0;
    .heading {
      font-size: 20px;
      color: #4a4a4a;
      font-family: "Inter-SemiBold", sans-serif !important;
    }
  }

  // -----------------------------

  // body css

  .notifi-modal-body {
    .cone-setting-message {
      position: absolute;
      top: 15px;
      left: -13px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 12px solid #4a4a4a;
      z-index: 2;
      border-bottom-right-radius: 8px;
      border-bottom: 6px solid transparent;
    }
    .info-settings {
      position: absolute;
      top: 6px;
      z-index: 2;
      left: -168px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      background-color: #4a4a4a;
      padding: 0px 10px;
      height: 30px;
      border-radius: 4px;
    }
    .notifi-item {
      display: flex;
      padding: 10px 16px;
      border-bottom: 1px solid #ebf2f5;
    }
    .notifi-item:hover {
      background-color: #f5f9fc;
    }
    .card-left {
      width: 5%;
      position: relative;
      .dot {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: #0171a1;
        margin-top: 15px;
      }
      .cone-div-message {
        position: absolute;
        top: 34px;
        left: 4px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 8px solid #4a4a4a;
      }
      .info-message {
        position: absolute;
        top: 42px;
        z-index: 2;
        left: -15px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        background-color: #4a4a4a;
        padding: 0px 10px;
        height: 25px;
        border-radius: 4px;
        z-index: 2;
      }
    }
    .card-right {
      width: 95%;
      margin-left: 10px;
      .item-head-row {
        font-size: 14px;
        font-weight: 500;
        color: #4a4a4a;
        .item-heading {
          font-weight: 600;
          font-size: 14px;
        }
        .id-text {
          color: #0171a1;
        }
      }
      .ref-row {
        margin-top: -15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .cont-ref-text {
          font-size: 12px;
          color: grey;
        }
        .time-text {
          font-size: 14px;
          color: #6d858f;
        }
        .cont-ref-text:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        .archive-section {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;

          svg {
            align-self: flex-end;
            cursor: pointer;
          }
          .cone-arch {
            position: absolute;
            top: 5px;
            right: 24px;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-left: 12px solid #4a4a4a;
            z-index: 2;
            border-bottom-right-radius: 8px;
            border-bottom: 6px solid transparent;
          }
          .info-div-arch {
            position: absolute;
            top: -4px;
            z-index: 2;
            right: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            background-color: #4a4a4a;
            padding: 0px 10px;
            height: 30px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  // -----------------------------

  // bottim css
  .btm-div {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 16px 3px 16px;
    box-shadow: 0px 2px 10px -1px rgba(41, 41, 41, 0.2);
    .btm-btn-text {
      font-size: 14px;
      color: #6d858f;
      font-family: "Inter-Medium";
    }
    .read-text {
      color: #0171a1;
    }
  }

  // -----------------------------

  // Empty Notification css
  .no-notification {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .heading-empty {
      margin-top: 20px;
      color: #4a4a4a;
      font-size: 20px;
      font-family: "Inter-SemiBold", sans-serif;
    }
    .para {
      color: #4a4a4a;
      font-size: 14px;
      font-family: "Inter-Regular";
      text-align: center;
      width: 75%;
    }
  }
}
.theme--light.v-select .v-select__selections {
  color: #6d858f;
  margin-left: 10px;
  margin-top: -4px;
}
</style>
<style>
.sortItems .v-input__control .v-input__slot::after {
  border: none;
}
.sortItems .v-input__control .v-icon {
  color: red !important;
}
.sortItems .v-input__control .v-input__slot {
  width: 158px;
}
.small .v-input__control .v-input__slot {
  width: 60px !important;
}
.med-small .v-input__control .v-input__slot {
  width: 130px !important;
}

/* .sortItems .v-select__selections {
  color: red;
} */

.sortItems .v-select__selections {
  color: #4a4a4a !important;
  font-size: 14px !important;
  font-weight: 600;
  font-family: "Inter-Medium", sans-serif !important;
}
</style>

<style lang="scss">
.v-dialog.message-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
  height: 100%;
  right: 0 !important;
  margin: 0;
  position: absolute !important;
  border-radius: 0 !important;
}

.v-dialog.message-dialog {
  .font-medium {
    font-family: "Inter-Medium", sans-serif !important;
  }

  .font-semibold {
    font-family: "Inter-SemiBold", sans-serif !important;
  }

  .v-card__title {
    &.modal-header {
      padding: 14px 20px !important;
      border-bottom-width: 1px !important;

      .modalheader_text {
        font-size: 20px;
        font-family: "Inter-Medium", sans-serif !important;
        margin-bottom: 0 !important;
        color: #4a4a4a;
        font-weight: unset !important;
      }
    }
  }

  .v-card__text {
    padding: 0px !important;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
    }
  }
}

.header_top {
  .v-toolbar__content {
    padding-left: 0px !important;
  }
}

.v-autocomplete__content {
  .v-list-item--active {
    background: #f0fbff !important;
    &:before {
      background: #f0fbff !important;
    }
  }
}

.header_top {
  padding-left: 0px !important;
  .v-toolbar__content {
    .v-toolbar__title {
      font-size: 24px;
    }
  }
}

.modale {
  &:before {
    content: "";
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}
.opened {
  &:before {
    display: block;
  }
}

.opened {
  .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 0;
  }
}

.v-select--is-menu-active {
  background: transparent !important;
}

.v-input__control {
  .v-input__slot {
    &:before {
      border-color: transparent !important;
    }
  }
}
</style>
